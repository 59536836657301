import React, { useState } from 'react';

import serverUrl from "../../../useful/config";

const Login = () => {
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');

  const username = 'admin';
  const password = 'admin';
  const credentials = btoa(`${username}:${password}`);

  const handleLogin = () => {
    fetch(`${serverUrl}usuario/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${credentials}`
      },
      body: JSON.stringify({ cpf, senha }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.auth) {
          localStorage.setItem('token', data.token);
          window.location.href = `/usuario/dashboard?cpf=${cpf}`;
        } else {
          alert('Login falhou. Verifique suas credenciais.');
        }
      })
      .catch((error) => {
        console.error('Erro na requisição:', error);
        alert('Cpf ou senha inválida');
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-pattern">
      <div className="bg-primary px-8 pt-16 pb-32 w-96 rounded-b-full">
        <h2 className="text-4xl font-bold text-center text-secondary mb-6 uppercase">Usuário</h2>
        <div className="mb-4">
          <input
            type="text"
            id="cpf"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            placeholder="CPF"
            className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-6">
          <input
            type="password"
            id="senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            placeholder="Senha"
            className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className='grid gap-4'>
          <div className='w-full flex justify-center'>
            <a href='recuperar-conta' className='bold text-secondary'>Esqueceu a senha?</a>
          </div>
          <div className="flex justify-center w-full">
            <button
              onClick={handleLogin}
              className="w-2/3 bg-secondary text-white py-2 rounded-full hover:bg-transparent border border-secondary transition duration-200"
            >
              Login
            </button>
          </div>
          <div className="flex justify-center w-full">
            <button
              onClick={() => (window.location.href = '/usuario/registro')}
              className="w-2/3 bg-transparent text-white py-2 rounded-full border border-secondary transition duration-200"
            >
              Cadastre-se
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
