import React from "react";
import Recover from "../../components/app/user/Recover";

const UserRecover = () => {
  return (
    <>
      <Recover />
    </>
  );
};

export default UserRecover;
