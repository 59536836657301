import { useNavigate } from "react-router-dom";

export const useToken = () => {
  const navigate = useNavigate();

  const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/usuario/login');
    }
    return token;
  };

  return { getToken };
};