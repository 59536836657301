import React, { useEffect, useState } from "react";
import Header from "../../components/app/user/dashboard/Header";
import Approval from "../../components/app/user/dashboard/Approval";
import Footer from "../../components/app/user/dashboard/Footer";


const Transition = () => {

    return (
        <div className="flex flex-col min-h-screen bg-gray-100 pb-32 md:pb-0">
            <Header />
            <Approval />
            <Footer />
        </div>
    );

}

export default Transition;
