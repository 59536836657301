import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../src/css/index.css';
import Home from "./pages/Home";
import SellerLogin from "./pages/seller/SellerLogin";
import SellerRegister from "./pages/seller/SellerRegister";
import UserLogin from "./pages/user/UserLogin";
import UserRegister from "./pages/user/UserRegister";
import UserRecover from "./pages/user/UserRecover";
import SellerDashboard from "./pages/seller/Dashboard";
import NotFound from "./pages/NotFound";
import UserDashboard from "./pages/user/Dashboard";
import Profile from "./pages/user/Profile";
import Transition from "./pages/user/Transition";
import Approval from "./pages/user/Approval";

createRoot(document.getElementById("root")).render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/vendedor/login" element={<SellerLogin />} />
      <Route path="/vendedor/registro" element={<SellerRegister />} />
      <Route path="/vendedor/dashboard" element={<SellerDashboard />} />
      <Route path="/usuario/login" element={<UserLogin />} />
      <Route path="/usuario/registro" element={<UserRegister />} />
      <Route path="/usuario/recuperar-conta" element={<UserRecover />} />
      <Route path="/usuario/dashboard" element={<UserDashboard />} />
      <Route path="/usuario/transacao" element={<Transition />} />
      <Route path="/usuario/aprovacao" element={<Approval />} />
      <Route path="/usuario/perfil" element={<Profile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);
