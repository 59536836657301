import React, { useState } from 'react';

import serverUrl from "../../../useful/config";

const Register = () => {
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [renda, setRenda] = useState('');

  const username = 'admin';
  const password = 'admin';
  const credentials = btoa(`${username}:${password}`);

  const handleRegister = () => {
    fetch(`${serverUrl}usuario`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${credentials}`
      },
      body: JSON.stringify({
        cpf: cpf,
        senha: senha,
        email,
        nomeCompleto: nomeCompleto,
        dataNascimento: dataNascimento,
        renda: renda
      })
    })
      .then((response) => {
        if (response.ok) {
          alert('Usuário registrado com sucesso!');
          window.location.href = '/usuario/login';
        } else {
          alert('Falha no registro do usuário.');
        }
      })
      .catch((error) => {
        console.error('Erro ao registrar usuário:', error);
        alert('Ocorreu um erro ao registrar. Tente novamente mais tarde.');
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-pattern my-16">
      <div className="bg-primary px-8 pt-16 pb-32 w-96 rounded-b-full">
        <h2 className="text-4xl font-bold text-center text-secondary mb-6 uppercase">Usuário</h2>
        <div className='grid gap-4 mb-4'>
          <div className="w-full">
            <input
              type="text"
              id="nomeCompleto"
              value={nomeCompleto}
              onChange={(e) => setNomeCompleto(e.target.value)}
              placeholder="Nome Completo"
              className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="w-full">
            <input
              type="text"
              id="cpf"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              placeholder="CPF"
              className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="w-full">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="w-full">
            <input
              type="date"
              id="dataNascimento"
              value={dataNascimento}
              onChange={(e) => setDataNascimento(e.target.value)}
              className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="w-full">
            <input
              type="number"
              id="renda"
              value={renda}
              onChange={(e) => setRenda(e.target.value)}
              placeholder="Renda"
              className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="w-full">
            <input
              type="password"
              id="senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              placeholder="Senha"
              className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="w-full">
            <input
              type="password"
              id="confirmar-senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              placeholder="Confirmar senha"
              className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            />
          </div>
        </div>
        <div className="flex justify-center mb-4">
          <button
            onClick={handleRegister}
            className="w-2/3 bg-secondary text-white py-2 rounded-full hover:bg-transparent border border-secondary transition duration-200"
          >
            Registrar
          </button>
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => (window.location.href = '/usuario/login')}
            className="w-2/3 bg-transparent text-white py-2 rounded-full border border-secondary transition duration-200"
          >
            Faça login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
