import React, { useState } from 'react';

import serverUrl from "../../../useful/config";

const Recover = () => {
  const [cpf, setCpf] = useState('');

  const username = 'admin';
  const password = 'admin';
  const credentials = btoa(`${username}:${password}`);

  const handleRecover = () => {
    fetch(`${serverUrl}usuario/recover`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${credentials}`
      },
      body: JSON.stringify({ cpf }),
    })
      .then((response) => {
        if (response.ok) {
          alert('Instruções para recuperação de conta foram enviadas para seu e-mail.');
          window.location.href = '/usuario/login';
        } else {
          alert('Falha na recuperação de conta. Verifique se o CPF está correto.');
        }
      })
      .catch((error) => {
        console.error('Erro ao recuperar conta:', error);
        alert('Ocorreu um erro. Tente novamente mais tarde.');
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-pattern">
      <div className="bg-primary px-8 pt-16 pb-32 w-96 rounded-b-full">
        <h2 className="text-4xl font-bold text-center text-secondary mb-6 uppercase">Recuperar Conta</h2>
        <div className="mb-4">
          <input
            type="text"
            id="cpf"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            placeholder="CPF"
            className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="flex flex-col items-center space-y-4 w-full">
          <button
            onClick={handleRecover}
            className="bg-secondary w-2/3 text-white py-2 rounded-full hover:bg-transparent border border-secondary transition duration-200"
          >
            Recuperar conta
          </button>
          <button
            onClick={() => (window.location.href = '/usuario/login')}
            className="bg-transparent w-2/3 text-white py-2 rounded-full border border-secondary transition duration-200"
          >
            Login
          </button>
          <button
            onClick={() => (window.location.href = '/usuario/registro')}
            className="bg-transparent w-2/3 text-white py-2 rounded-full border border-secondary transition duration-200"
          >
            Cadastre-se
          </button>
        </div>
      </div>
    </div>
  );
};

export default Recover;
