import React from "react";
import Register from "../../components/app/user/Register";

const UserRegister = () => {
  return (
    <>
      <Register />
    </>
  );
};

export default UserRegister;
