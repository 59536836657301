import React from "react";
import Login from "../../components/app/user/Login";

const UserLogin = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default UserLogin;
