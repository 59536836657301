import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SellerDashboard from "../../components/app/seller/Dashboard";

const Dashboard = () => {
        

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <SellerDashboard />
        </div>
    );
};

export default Dashboard;
