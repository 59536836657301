import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import serverUrl from "../../../../useful/config";

const Modal = ({ isOpen, onClose, transaction }) => {
    const handleApproval = async (autorizador) => {
        try {
            const response = await fetch(`${serverUrl}venda/aprovar/${autorizador}/${transaction.id}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                console.log("Transação atualizada com sucesso.");
                onClose();
            } else {
                console.error("Erro ao atualizar a transação:", await response.text());
            }
        } catch (error) {
            console.error("Erro ao enviar a solicitação:", error);
        }
    };

    if (!isOpen || !transaction) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-2/3 md:w-1/2">
                <h4 className="text-lg font-semibold">{transaction.nomeProduto}</h4>
                <p className="text-sm text-gray-500">Vendedor: {transaction.vendedor.nomeEmpresa}</p>
                <p className="text-lg font-semibold text-red-500">-${transaction.valor.toFixed(2)}</p>
                <button
                    onClick={() => handleApproval(1)}
                    className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mr-4"
                >
                    Aprovar
                </button>
                <button
                    onClick={() => handleApproval(0)}
                    className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 mr-4"
                >
                    Reprovar
                </button>
                <button
                    onClick={onClose}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Fechar
                </button>
            </div>
        </div>
    );
};

const Approval = () => {
    const [transactions, setTransactions] = useState([]);
    const [searchParams] = useSearchParams();
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const cpf = searchParams.get("cpf");
    const username = 'admin';
    const password = 'admin';
    const credentials = btoa(`${username}:${password}`);

    useEffect(() => {
        const fetchTransactions = async () => {
            if (cpf) {
                try {
                    const response = await fetch(`${serverUrl}venda/busca-pendente/${cpf}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Basic ${credentials}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setTransactions(data);
                    } else {
                        console.error('Erro ao carregar o histórico de transações:', await response.text());
                    }
                } catch (error) {
                    console.error('Erro ao carregar o histórico de transações:', error);
                }
            }
        };

        fetchTransactions();
    }, [cpf]);

    const handleTransactionClick = (transaction) => {
        console.log('Transação clicada:', transaction); 
        setSelectedTransaction(transaction);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTransaction(null);
    };

    return (
        <section className="md:w-2/3 mx-auto p-6 md:p-8 lg:p-12 bg-gray-100">
            <h3 className="text-2xl font-semibold mb-6 text-gray-800">Aprovar compra</h3>
            <div className="space-y-6">
                {transactions.length > 0 ? (
                    transactions.map((transaction) => (
                        <div
                            key={transaction.id}
                            onClick={() => handleTransactionClick(transaction)}
                            className="cursor-pointer rounded-lg shadow-lg border border-gray-200 bg-white p-6 transition-transform transform hover:scale-105 hover:shadow-xl"
                        >
                            <div className="flex justify-between items-center">
                                <div>
                                    <h4 className="text-lg font-semibold text-gray-700">
                                        {transaction.nomeProduto}
                                    </h4>
                                    <p className="text-sm text-gray-500">
                                        Vendedor: {transaction.vendedor.nomeEmpresa}
                                    </p>
                                </div>
                                <p className="text-lg font-semibold text-red-500">
                                    -${transaction.valor}
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-500">Nenhuma transação encontrada.</p>
                )}
            </div>
            <Modal 
                isOpen={isModalOpen} 
                onClose={closeModal} 
                transaction={selectedTransaction} 
            />
        </section>
    );
};

export default Approval;
