import React, { useState } from 'react';

import serverUrl from "../../../useful/config";

const Register = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [nomeEmpresa, setNomeEmpresa] = useState('');

  const username = 'admin';
  const password = 'admin';
  const credentials = btoa(`${username}:${password}`);

  const handleRegister = () => {
    fetch(`${serverUrl}vendedor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${credentials}`
      },
      body: JSON.stringify({
        email: email,
        senha: senha,
        nomeEmpresa: nomeEmpresa
      })
    })
      .then((response) => {
        if (response.ok) {
          alert('Vendedor registrado com sucesso!');
          window.location.href = '/vendedor/login';
        } else {
          alert('Falha no registro do vendedor.');
        }
      })
      .catch((error) => {
        console.error('Erro ao registrar vendedor:', error);
        alert('Ocorreu um erro ao registrar. Tente novamente mais tarde.');
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-pattern">
      <div className="bg-primary px-8 pt-16 pb-32 w-96 rounded-b-full">
        <h2 className="text-4xl font-bold text-center text-secondary mb-6 uppercase">Vendedor</h2>
        <div className="mb-4">
          <input
            type="text"
            id="nomeEmpresa"
            value={nomeEmpresa}
            onChange={(e) => setNomeEmpresa(e.target.value)}
            placeholder="Nome da Empresa"
            className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-6">
          <input
            type="password"
            id="senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            placeholder="Senha"
            className="w-full px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="flex justify-center mb-4">
          <button
            onClick={handleRegister}
            className="w-2/3 bg-secondary text-white py-2 rounded-full hover:bg-transparent border border-secondary transition duration-200"
          >
            Registrar
          </button>
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => (window.location.href = '/vendedor/login')}
            className="w-2/3 bg-transparent text-white py-2 rounded-full border border-secondary transition duration-200"
          >
            Faça login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
