import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FaHome, FaListAlt, FaUser } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const cpf = searchParams.get("cpf");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setProfileDropdownOpen(false);
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
    setDropdownOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove o token do localStorage
    window.location.href = '/usuario/login'; // Redireciona para a página de login
  };

  return (
    <header className="top-0 z-10 bg-white shadow-md">
      <div className="container mx-auto flex items-center justify-between p-4">
        <a href={`./dashboard?cpf=${cpf}`} className="text-2xl font-bold text-primary">Unipay Later</a>
        <nav className="flex items-center space-x-6">
          <div className="hidden md:relative md:block">
            <button
              onClick={toggleDropdown}
              className="flex items-center text-lg font-semibold text-gray-700 focus:outline-none"
            >
              Menu
            </button>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg border border-gray-200 z-10">
                <a
                  href={`./dashboard?cpf=${cpf}`}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <FaHome className="inline-block mr-2" /> Dashboard
                </a>
                <a
                  href={`./transacao?cpf=${cpf}`}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <FaListAlt className="inline-block mr-2" /> Transação
                </a>
                <a
                  href={`./aprovacao?cpf=${cpf}`}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  <FaCheck className="inline-block mr-2" /> Aprovação
                </a>
              </div>
            )}
          </div>
          <div className="hidden md:relative md:block">
            <button
              onClick={toggleProfileDropdown}
              className="flex items-center text-lg font-semibold text-gray-700 focus:outline-none"
            >
              <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center mr-2">
                <FaUser className="text-white" />
              </div>
              Perfil
            </button>
            {profileDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg border border-gray-200 z-40">
                <a
                  href={`./perfil?cpf=${cpf}`}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Perfil
                </a>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Sair
                </button>
              </div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
