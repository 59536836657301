import React from "react";
import responsive from "../assets/responsive.png";

const Budget = () => {
  return (
    <>
      <div className="flex flex-col xl:flex-row h-screen">
        <div className="w-full xl:w-1/2 z-10 align-center bg-light xl:pt-48 py-16 my-auto flex">
          <img src={responsive} alt="visualização de layout" className="h-auto mx-auto w-10/12 md:w-1/2 xl:w-2/3" />
        </div>
        <div className="w-full xl:w-1/2 z-10 bg-primary xl:pt-48 py-16">
          <div className="w-8/12 mx-auto">
            <p className="xl:text-6xl text-3xl font-patter-bold uppercase text-secondary">
              <strong>Simplificamos suas compras</strong>
            </p>
            <p className="text-2xl mt-12 mb-9 text-justify text-white">
              Somos a Unipay, oferecendo a você uma maneira fácil e prática de comprar com pagamento parcelado diretamente na folha de pagamento. Menos burocracia e mais comodidade.
            </p>
            <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank" className="bg-secondary text-black py-2 px-6 rounded-full transition mt-8 w-full xl:w-2/3 block text-xl text-center">
              Fale com a Unipay
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Budget;
