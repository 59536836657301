import React from "react";
import Login from "../../components/app/seller/Login";

const SellerLogin = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default SellerLogin;
