import React, { useState } from "react";
import logo from '../assets/logo.png';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="mt-16 w-full block px-8 z-50 xl:px-0 xl:bg-transparent xl:absolute">
      <nav className="w-11/12 mx-auto py-6 flex justify-between items-center  bg-secondary px-16 rounded-full">
        <div>
          <a href="/" className="inline-block">
            <img src={logo} alt="Logo" className="w-32 xl:w-44 brightness-1000" />
          </a>
        </div>
        <div className="xl:hidden">
          <button
            className="text-white hover:text-black focus:outline-none p-2"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? (
              <i className="fa-solid fa-times text-xl"></i>
            ) : (
              <i className="fa-solid fa-bars text-xl"></i>
            )}
          </button>
        </div>
        <ul className="hidden xl:flex space-x-4 items-center">
          <li>
            <a href="/" className="text-black hover:text-black font-semibold bg-white px-4 py-2  rounded-full">
              Início
            </a>
          </li>
          <li>
            <a href="/usuario/login" className="text-black hover:text-black font-semibold bg-white px-4 py-2  rounded-full">
              Sou Usuário
            </a>
          </li>
          <li>
            <a href="/vendedor/login" className="text-black hover:text-black font-semibold bg-white px-4 py-2  rounded-full">
              Sou Vendedor
            </a>
          </li>
          <li>
            <a href="/empresa/login" className="text-black hover:text-black font-semibold bg-white px-4 py-2  rounded-full">
              Sou Empresa
            </a>
          </li>
        </ul>
        <div
          className={`fixed inset-0 bg-primary z-40 transform ${isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
        >
          <button
            className="absolute top-6 right-6 text-white text-3xl focus:outline-none"
            onClick={toggleMobileMenu}
          >
            <i className="fa-solid fa-times"></i>
          </button>

          <ul className="flex flex-col items-center justify-center h-screen">
            <li className="mb-8">
              <a
                href="/"
                className="text-white text-2xl hover:text-black block"
              >
                Início
              </a>
            </li>
            <li className="mb-8">
              <a
                href="/usuario/login"
                className="text-white text-2xl hover:text-black block"
              >
                Sou Usuário
              </a>
            </li>
            <li className="mb-8">
              <a
                href="/vendedor/login"
                className="text-white text-2xl hover:text-black block"
              >
                Sou Vendedor
              </a>
            </li>
            <li className="mb-8">
              <a
                href="/empresa/login"
                className="text-white text-2xl hover:text-black block"
              >
                Sou Empresa
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
