import React from "react";
import Register from "../../components/app/seller/Register";

const SellerRegister = () => {
  return (
    <>
      <Register />
    </>
  );
};

export default SellerRegister;
