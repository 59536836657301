import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";

import oticamais from "../../../../assets/oticamais.png";
import rbcelulares from "../../../../assets/rbcelulares.jpg";
import oticasrarita from "../../../../assets/oticasrarita.jpg";
import mundopink from "../../../../assets/mundopink.jpg";
import sem_foto from "../../../../assets/sem_foto.png";

const allClients = [
    { name: "Ótica Mais", city: "Orleans" },
    { name: "Mundo Pink", city: "Braço do Norte" },
    { name: "RB Celulares", city: "Lauro Muller" },
    { name: "Óticas Raritá", city: "Treviso" },
    { name: "Bussolo Imports", city: "Orleans" },
    { name: "Mara Modas", city: "Orleans" },
    { name: "Cacique Moto Peças", city: "Orleans" },
    { name: "Rover Motos (Acessórios)", city: "Orleans" },
    { name: "Ferrugem Surf e Skate", city: "Orleans" },
    { name: "Revitalize Estética Especializada", city: "Orleans" },
    { name: "Jet Med Orleans e BN", city: "Orleans" },
    { name: "For the Dress (loja da Sania)", city: "Orleans" },
    { name: "Infotech", city: "Orleans" },
    { name: "Alecrim Dourado", city: "Orleans" },
    { name: "AJ Company", city: "Orleans" },
    { name: "La Bella by Gi Nieiro", city: "Orleans" },
    { name: "Ótica Pacheco", city: "Orleans" },
    { name: "Doce Desejo", city: "Orleans" },
    { name: "Taboo Acessórios", city: "Orleans" },
    { name: "Conecta", city: "Orleans" },
    { name: "Jet Med", city: "Braço do Norte" },
    { name: "Lara Modas", city: "Braço do Norte" },
    { name: "Bem Bem Kids", city: "Braço do Norte" },
    { name: "Qlinda", city: "Braço do Norte" },
    { name: "Estima", city: "Braço do Norte" },
    { name: "Minamo", city: "Braço do Norte" },
    { name: "Chica Maria", city: "Braço do Norte" },
    { name: "Damas Calçados", city: "Braço do Norte" },
    { name: "Coisas de Taty", city: "Braço do Norte" },
    { name: "Império Cosméticos", city: "Braço do Norte" },
    { name: "Manas Bakanas", city: "Braço do Norte" },
    { name: "Di Conceito", city: "Braço do Norte" },
    { name: "Ponto Chick", city: "Braço do Norte" },
    { name: "RB Presentes", city: "Lauro Muller" },
    { name: "Beatriz Calçados", city: "Lauro Muller" },
    { name: "Slin Fashion", city: "Lauro Muller" },
    { name: "Malu Cosméticos", city: "Lauro Muller" },
];

const getClientPhoto = (name) => {
    if (name.includes("Ótica")) return oticamais;
    if (name.includes("RB Celulares")) return rbcelulares;
    if (name.includes("Óticas Raritá")) return oticasrarita;
    if (name.includes("Mundo Pink")) return mundopink;
    return sem_foto;
};

const ClientList = () => {
    const [selectedCity, setSelectedCity] = useState("All");
    const filteredClients = selectedCity === "All"
        ? allClients
        : allClients.filter(client => client.city === selectedCity);

    return (
        <section className="p-6">
            <div className="flex">
                <div className="mb-6 mr-8">
                    <h3 className="text-xl font-semibold mb-4">Clientes Vinculados</h3>
                </div>
                <div className="mb-6">
                    <select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className="p-2 border rounded"
                    >
                        <option value="All">Todas as Cidades</option>
                        <option value="Orleans">Orleans</option>
                        <option value="Braço do Norte">Braço do Norte</option>
                        <option value="Lauro Muller">Lauro Muller</option>
                        <option value="Treviso">Treviso</option>
                    </select>
                </div>
            </div>

            <Swiper
                spaceBetween={20}
                autoplay={{
                    delay: 3000, 
                    disableOnInteraction: false,
                }}
                loop={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    350: {
                        slidesPerView: 1.5,
                    },
                    550: {
                        slidesPerView: 2.5,
                    },
                    780: {
                        slidesPerView: 3.5,
                    },
                    1024: {
                        slidesPerView: 4.5,
                    },
                    1500: {
                        slidesPerView: 5.5,
                    },
                    1700: {
                        slidesPerView: 7.5,
                    },
                }}
            >
                {filteredClients.map((client, index) => (
                    <SwiperSlide key={index}>
                        <div className="w-[200px] rounded-lg border bg-white">
                            <img src={getClientPhoto(client.name)} alt={client.name} className="rounded-t-lg" />
                            <div className="p-4">
                                <h4 className="font-semibold mb-2">{client.name}</h4>
                                <p className="text-sm text-gray-500">{client.city}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};

export default ClientList;
