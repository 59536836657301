import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import defaultProfile from '../../../assets/usuario.png';
import serverUrl from "../../../useful/config";
import { useToken } from "./utilities/token"; 

const Profile = () => {
  const [userData, setUserData] = useState({
    nomeCompleto: '',
    email: '',
    telefone: '',
    foto: defaultProfile,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [searchParams] = useSearchParams();
  const cpf = searchParams.get('cpf');
  const { getToken } = useToken();
  const token = getToken();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${serverUrl}usuario/${cpf}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        setIsEditing(false);
        alert('Dados atualizados com sucesso!');
      } else {
        console.error('Erro ao atualizar dados:', await response.text());
      }
    } catch (error) {
      console.error('Erro ao atualizar dados:', error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (cpf) {
        try {
          const response = await fetch(`${serverUrl}usuario/${cpf}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUserData(data);
          } else {
            console.error('Erro ao buscar dados do usuário:', await response.text());
          }
        } catch (error) {
          console.error('Erro ao buscar dados do usuário:', error);
        }
      }
    };

    fetchUserData();
  }, [cpf, token]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-pattern pb-16">
      <div className="bg-primary px-8 pt-16 pb-32 w-96 rounded-b-full">
        <h2 className="text-4xl font-bold text-center text-secondary mb-6 uppercase">Perfil</h2>
        <div className="flex justify-center mb-6">
          <img
            src={userData.foto || defaultProfile}
            alt="Foto do usuário"
            className="h-24 w-24 rounded-full object-cover border-2 border-secondary"
          />
        </div>

        <div className="mb-4">
          <label className="text-secondary">Nome:</label>
          <input
            type="text"
            name="nome"
            value={userData.nomeCompleto}
            onChange={handleChange}
            disabled={!isEditing}
            className="w-full bg-white px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>

        <div className="mb-4">
          <label className="text-secondary">CPF:</label>
          <input
            type="text"
            name="cpf"
            value={userData.cpf}
            disabled
            className="w-full bg-white px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>

        <div className="mb-4">
          <label className="text-secondary">E-mail:</label>
          <input
            type="text"
            name="email"
            value={userData.email}
            onChange={handleChange}
            disabled={!isEditing}
            className="w-full bg-white px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>

        <div className="mb-4">
          <label className="text-secondary">Data de Nascimento:</label>
          <input
            type="date"
            name="dataNascimento"
            value={userData.dataNascimento}
            onChange={handleChange}
            disabled={!isEditing}
            className="w-full bg-white px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>

        <div className="mb-4">
          <label className="text-secondary">Telefone:</label>
          <input
            type="text"
            name="telefone"
            value={userData.telefone}
            onChange={handleChange}
            disabled={!isEditing}
            className="w-full bg-white px-4 py-2 border border-secondary rounded-full focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>

        <div className="flex justify-between mt-6">
          {isEditing ? (
            <div className="flex flex-col items-center space-y-4 w-full">
              <button
                onClick={handleSave}
                className="w-2/3 bg-secondary text-white py-2 rounded-full hover:bg-transparent border border-secondary transition duration-200 mr-2"
              >
                Salvar
              </button>
              <button
                onClick={handleEditToggle}
                className="w-2/3 bg-gray-500 text-white py-2 rounded-full hover:bg-gray-600 transition duration-200 mr-2"
              >
                Cancelar
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-4 w-full">
              <button
                onClick={handleEditToggle}
                className="w-2/3 bg-yellow-500 text-white py-2 rounded-full hover:bg-yellow-600 transition duration-200"
              >
                Editar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
